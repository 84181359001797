import EventBus from '@/util/EventBus'
import { popupCenter } from '@/util/Popup.js'
import { InlineConnection } from '@/models/developers/InlineConnection'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import DeveloperService from '@/services/developer.service'
import KlaviyoConnectionDialog from './Dialogs/KlaviyoConnectionDialog.vue'

export default {
  name: 'PluginKlaviyo',
  components: {
    HeaderTopDashboard,
    KlaviyoConnectionDialog,
  },
  data () {
    return {
      docTranslations: ['es', 'en', 'pt'],
      klaviyo: {},
      inline: new InlineConnection(),
    }
  },
  computed: {},
  watch: {},
  created () {
    this.getPluginModulesAndIntegrations()
  },
  mounted () {},
  methods: {
    getPluginModulesAndIntegrations () {
      const user = JSON.parse(localStorage.getItem('user'))
      const lang = this.$i18n.locale || user.lang
      const selectedTranslation = this.docTranslations.includes(lang) ? lang : 'en'
      const params = {
        lang: selectedTranslation,
      }
      DeveloperService.getPluginModulesAndIntegrations(params)
        .then(
          (response) => {
            this.klaviyo = response.integrations.klaviyo
            this.initInlineIntegrations(response.integrations)
          },
          (error) => {
            console.log(error)
          },
        )
    },
    viewDoc (url, title) {
      popupCenter(url, title, 1000, 600)
    },
    viewUrl (url, title) {
      popupCenter(url, title, 1000, 600)
    },
    initInlineIntegrations (integrations) {
      Object.values(integrations)
        .filter(item => item.type === 'inline')
        .map(item => {
          this.inline.connectionDialogs[item.slug] = false
        })
    },
    showDialog (itemName) {
      return this.inline.connectionDialogs[itemName] || false
    },
    connectInline (itemName) {
      itemName = itemName.toLowerCase()
      this.$set(this.inline.connectionDialogs, itemName, true)
    },
    disconnectInline (item) {
      const itemName = item.name.toLowerCase()
      const serviceName = itemName.charAt(0).toUpperCase() + itemName.slice(1)
      DeveloperService.disconnectInlineIntegrationApiKey({ slug: itemName, apiKey: null })
        .then(() => {
          item.status = false
          EventBus.$emit(
            'showAlert',
            'success',
            this.$t('Se ha desconectado de ') + serviceName,
          )
        }, () => {
          EventBus.$emit(
            'showAlert',
            'danger',
            this.$t('Se produjo un error al desconectar ' + serviceName),
          )
        })
    },
    onCloseDialog (itemName) {
      itemName = itemName.toLowerCase()
      this.$set(this.inline.connectionDialogs, itemName, false)
    },
    onConnected (itemName) {
      itemName = itemName.toLowerCase()
      this.klaviyo.status = true
    },
  },
}
